<template>
    <div>
        <v-container fluid class="pt-0 mt-0" style="position: fixed; top:0; z-index: 99">
            <HeaderAzul titulo="E.G." subtitulo="Buddy (beta)"/>
        </v-container>
        <v-container fluid style="margin-top:120px;" :style="{'padding-bottom' : $vuetify.breakpoint.mdAndDown ? '65px' : '10px'}">
            <v-row>
                <v-col cols="12" v-for="(c, i) in chat" :key="i" :class="{'text-right' : !c.assistente}">
                    <v-card style="display: inline-block" :color="!c.assistente ? '#FFD600' : '#F0F0F0'">
                        <v-card-text class="pt-4 mt-0">
                            <span style="font-size: 18px;">{{c.mensagem}}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-alert class="mt-6 mb-0" v-if="erro" type="error" style="text-align: left">{{erro}}</v-alert>
        </v-container>
        <v-footer fixed style="z-index: 99" :style="{'bottom' : $vuetify.breakpoint.mdAndDown ? '55px' : 0}" color="#005FAB" class="pa-3">
            <v-text-field :disabled="carregando" :style="{'margin-left': $vuetify.breakpoint.mdAndDown ? 0 : '250px'}" hide-details solo label="Enviar mensagem..." v-model="registro.chat" @keyup.enter="perguntar" />
            <v-btn :loading="carregando" icon color="white" @touchstart.passive="microfoneInicio" @touchend.passive="microfoneFim" @mousedown.prevent="microfoneInicio" @mouseup.prevent="microfoneFim" @mouseleave.prevent="microfoneFim" @click.prevent="">
                <v-icon>mdi-microphone</v-icon>
            </v-btn>
        </v-footer>
    </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
export default {
    name: "Cursos",
    components: {HeaderAzul},
    data: () => ({
        chat : [],
        registro : {
            chat : null,
            audio : null
        },
        mediaRecorder : null,
        chunks : [],
        carregando : false,
        erro : null
    }),
    computed : {
        ...mapState(['apiUrl', 'usuarioSessao'])
    },
    methods : {
        scrollToBottom() {
            window.scrollTo(0,document.body.scrollHeight + 200)
        },
        async listar() {
            this.carregando = true
            await axios.post(`${this.apiUrl}assistente/chat/listar`, ).then( (res) => {
                this.chat = res.data.lista
            })
            this.carregando = false
            this.scrollToBottom()
        },
        async perguntar() {
            if(this.carregando) {
                return
            }
            if(!this.registro) {
                this.erro = "É obrigatório informar uma mensagem."
                return
            }
            this.carregando = true
            await axios.post(`${this.apiUrl}assistente/chat/novo`, this.registro).then( (res) => {
                this.registro = {}
                this.chat = this.chat.concat(res.data)
                let audio = new Audio('data:audio/mpeg;base64,'+res.data.filter((v) => {
                    return v.assistente
                })[0].audio)
                audio.play()
            })
            this.scrollToBottom()
            this.carregando = false
        },
        async perguntarAudio(audiob64) {
            if(this.carregando) {
                return
            }
            if(!this.registro) {
                this.erro = "É obrigatório informar uma mensagem."
                return
            }
            this.carregando = true
            await axios.post(`${this.apiUrl}assistente/chat/audio/novo`, {audio : audiob64}).then( (res) => {
                this.registro = {}
                this.chat = this.chat.concat(res.data)
                let audio = new Audio('data:audio/mpeg;base64,'+res.data.filter((v) => {
                    return v.assistente
                })[0].audio)
                audio.play()
            }).catch((e) => {
                this.erro = e.response.data
            })
            this.carregando = false
            this.scrollToBottom()
        },
        microfoneInicio() {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = e => {
                        this.chunks.push(e.data);
                    };
                    this.mediaRecorder.start();
                });
        },
        microfoneFim() {
            if (this.mediaRecorder) {
                let that = this
                setTimeout(function() {
                    that.mediaRecorder.stop()
                    that.mediaRecorder.onstop = () => {
                        const blob = new Blob(that.chunks, { type : 'audio/mp4' })
                        // const audio = new Audio();
                        // audio.src = URL.createObjectURL(blob);
                        // audio.load();
                        // audio.play();
                        that.blobToBase64(blob, that.perguntarAudio)
                        that.mediaRecorder = null
                        that.chunks = []
                    }
                }, 300)
            }
        },
        blobToBase64(blob, callback) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64AudioMessage = reader.result.split(',')[1];
                callback(base64AudioMessage);
            };
            reader.readAsDataURL(blob);
        }
    },
    activated() {
        this.listar();
    }
}
</script>

<style scoped>
    h2 {font-size: 42px; font-weight: lighter; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
</style>